import React, { useState, useRef } from "react"
import { Button, TextBox, FileUploader } from 'devextreme-react';
import { DataGrid, SearchPanel, HeaderFilter, Search, Column, Pager } from 'devextreme-react/data-grid';

import notify from 'devextreme/ui/notify';
import * as Fnctn from '../../../contexts/GenericFunctions';
import { halcoloaderActive, halcoloaderRemove } from '../../../contexts/HalcoLoader'
import * as FireBase from '../../../contexts/FirebaseActions'
import * as XLSX from 'xlsx';
import { connect } from 'react-redux'
import { RootState } from '../../../reduxStore/reducers'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { GetNamesEmployees } from '../../../components/Evaluations/ComponentsEvaluation'

interface IProps {
}

const NewEvaluation: React.FC<IProps> = (props: any) => {
    const [base64Image, setBase64Image] = useState<string>("");
    const [FotoTarea, setFotoTarea] = useState<File | null>(null);
    const [Titulo, setTitulo] = useState<string>(props.Select_Title);
    const [Descripcion, setDescripcion] = useState<string>(props.Select_Description);
    const [dtEvaluators, setDtEvaluators] = useState<any[]>(props.Select_Evaluators);
    const [dtEvaluateds, setDtEvaluateds] = useState<any[]>(props.Select_Evaluateds);
    const [dtEmployeesTemplate, setDtEmployeesTemplate] = useState<[{ cvetraEvaluator: string, cvetraEvaluated: string }] | any[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    const handleTextChanged = (e: any, Textbox: string = "") => {
        if (Textbox === "Titulo") setTitulo(e.value);
        if (Textbox === "Descripcion") setDescripcion(e.value);
    };

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        setFotoTarea(file);
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String: string = reader.result!.toString();
                setBase64Image(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    const getLastIndex = async () => {
        const data = Fnctn.getCookie();
        const api = Fnctn.GetAPI();
        const dtresult = await fetch(`${api}/rating/evaluations`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${data.token}`
            }
        });
        const dtEvalautions = await dtresult.json();
        let max: number = -1;
        dtEvalautions.data.forEach((element: any) => {
            if (max < element.order) {
                max = element.order;
            }
        });

        return max + 1;
    }

    const Guardar = async () => {
        try {
            halcoloaderActive();
            const data = Fnctn.getCookie();
            const api = Fnctn.GetAPI();
            let index = await getLastIndex();

            if (!Fnctn.NullUndefinedEmpty(Titulo)) {
                if (!Fnctn.NullUndefinedEmpty(Descripcion)) {
                    if (!Fnctn.NullUndefinedEmpty(FotoTarea)) {
                        let IconoTarea = await FireBase.uploadFile(FotoTarea, FireBase.enumPath.ARCHIVOS_EVALUACIONES_TH);
                        let InsertForm: { [key: string]: any } = await {
                            "name": Titulo,
                            "type": "E",
                            "questionnaire": [
                                {
                                    "_id": "",
                                    "title": Titulo,
                                    "description": "",
                                    "questions": [
                                        {
                                            "_id": "",
                                            "question": "Primera pregunta",
                                            "response_type": "text",
                                            "mandatory": false,
                                            "answers": [],
                                            "evaluation": false,
                                            "score": false,
                                            "correct_answers": [],
                                            "points": 0,
                                            "explanation": ""
                                        }
                                    ]
                                }
                            ]
                        }

                        const dtresult = await fetch(`${api}/training/evaluations`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${data.token}`
                            },
                            body: JSON.stringify(InsertForm)
                        });
                        const evaluationsResult = await dtresult.json();

                        let Objt = {
                            "name": Titulo
                            , "description": Descripcion
                            , "employees_template": dtEmployeesTemplate
                            , "img_path": IconoTarea.PathFireBase
                            , "img_name": IconoTarea.NameFirebase
                            , "order": index
                            , "form_id": evaluationsResult.data.insertedId
                            , "deployed": false
                        }

                        let dtEvaluations: any = await fetch(`${api}/rating/evaluations`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${data.token}`
                            },
                            body: JSON.stringify(Objt)
                        });

                        dtEvaluations = await dtEvaluations.json();
                        if (dtEvaluations.valid) {
                            for (let i: number = 0; i < dtEvaluators.length; i++) {

                                let Objt = {
                                    "evaluation_id": dtEvaluations.data.insertedId,
                                    "evaluator_cvetra": dtEvaluators[i].cvetra,
                                    "evaluator_name": dtEvaluators[i].name_employee,
                                    "evaluated_cvetra": dtEvaluateds[i].cvetra,
                                    "evaluated_name": dtEvaluateds[i].name_employee,
                                    "levelEvaluator": dtEvaluators[i].levelEvaluator
                                }

                                let assignationResult: any = await fetch(`${api}/rating/assign_evaluation`, {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json',
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${data.token}`
                                    },
                                    body: JSON.stringify(Objt)
                                });
                                assignationResult = await assignationResult.json();

                                notify("Evaluacion agregada correctamente", "success");
                                navigate('/Evaluations');
                            }
                        }
                        else throw (dtEvaluations.message);
                        halcoloaderRemove();
                    } else throw ("Debes agregar una imagen para el ícono de la evaluación");
                } else throw ("Debes agregar una descripción");
            } else throw ("Debes agregar un título");
        }
        catch (error) {
            halcoloaderRemove();
            notify(error, 'error');
        }
    };

    const handleClick = () => {
        if (fileInputRef.current) fileInputRef.current.click();
    };

    const getFiles = (dataFile: any) => {
        const file = dataFile.value[0];
        const reader = new FileReader();
        let Evaluadores: any[] = [];
        let employeesList: [{ cvetraEvaluator: string, levelEvaluator: string, cvetraEvaluated: string }] | any[] = [];
        reader.onload = async (e: any) => {
            try {
                const ab = e.target.result;
                const wb = XLSX.read(ab, { type: 'array' });
                const ws = wb.Sheets[wb.SheetNames[0]];
                const jsonData = XLSX.utils.sheet_to_json(ws, { header: 1 });
                const [header, ...rows]: any = jsonData;
                let dtExcel = rows.map((row: any) => {
                    const obj: any = {};
                    header.forEach((key: any, index: any) => {
                        if (!Fnctn.NullUndefinedEmpty(row[index])) obj[key] = row[index];
                        else obj[key] = "-";
                    });
                    return obj;
                });

                dtExcel.forEach((item: any) => {
                    if (item.Evaluadores !== '-') {
                        if (item.Nivel !== '-') {
                            if (item.Evaluados !== '-') {
                                employeesList.push({ cvetraEvaluator: item.Evaluadores, levelEvaluator: item.Nivel, cvetraEvaluated: item.Evaluados });
                            }
                            else notify("Falta agregar un evaluado en la lista", "error")
                        }
                        else notify("Todos los evaluadores deben llevar un Nivel", "error")
                    }
                    else notify("Falta agregar un evaluador en la lista", "error")
                });

                let Evaluators: any[] = [];
                let Evaluateds: any[] = [];

                employeesList.forEach((item: any) => {
                    Evaluators.push(item.cvetraEvaluator);
                    Evaluateds.push(item.cvetraEvaluated);
                });

                let arrayEmployes: any = {
                    Evaluadores: Evaluators,
                    Evaluados: Evaluateds
                };

                let EmployeesResult: any = await GetNamesEmployees(arrayEmployes, false, "");

                EmployeesResult[0].forEach((item: any) => {
                    if (item.name_employee === "No encontrado") throw (`El No. Empleado ${item.cvetra} no fue encontrado`);
                })
                EmployeesResult[1].forEach((item: any) => {
                    if (item.name_employee === "No encontrado") throw (`El No. Empleado ${item.cvetra} no fue encontrado`);
                })

                setDtEmployeesTemplate(employeesList);
                for (let i: number = 0; i < EmployeesResult[0].length; i++) {
                    EmployeesResult[0][i].levelEvaluator = await employeesList[i].levelEvaluator;
                }
                setDtEvaluators(EmployeesResult[0]);
                setDtEvaluateds(EmployeesResult[1]);
            }
            catch (error) {
                halcoloaderRemove();
                notify(error, "error");
            }
        };

        reader.readAsArrayBuffer(file);
    }

    const renderRowIndex = (cellData: any) => <span>{cellData.rowIndex + 1}</span>;

    return (
        <React.Fragment>

            <div className='btnAtrasNewEvalaution'>
                <Link to={'/Evaluations'}>
                    <Button className='' icon="back" text="Atras"></Button>
                </Link>
            </div>
            <div className={'content-block dx-card responsive-paddings cardEditNewEvaluation'}>
                <div style={{ margin: '0px 0px 40px 0px' }}><label style={{ fontSize: '25px' }}>Nueva Evaluación</label></div>
                <div className={'form-avatar'} style={{ cursor: 'pointer', borderRadius: '10%', margin: "0px 0px 0px 30px" }} onClick={handleClick}>
                    <img
                        alt={''}
                        src={base64Image}
                        style={{ width: '100%', height: '100%', objectFit: 'contain', borderColor: "#000" }}
                    />
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="*"
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                    />
                </div>
                <div style={{ margin: '0px 0px 0px 10%', width: "auto" }}>
                    <TextBox
                        label='Titulo'
                        placeholder='Debes ingresar un título'
                        onValueChanged={(e) => handleTextChanged(e, "Titulo")}
                    ></TextBox>
                    <TextBox
                        className="txtDescripcionEvaluacion"
                        label='Descripción'
                        placeholder='Debes ingresar una descripción'
                        onValueChanged={(e) => handleTextChanged(e, "Descripcion")}
                        width={"100%"}
                    ></TextBox>
                    <Button
                        style={{ margin: '15px 0px 0px 0px' }}
                        stylingMode='contained'
                        type='success'
                        text='Guardar'
                        onClick={() => Guardar()}
                    ></Button>
                </div>
                <div className='AccordionAudienciaEvaluations'>
                    <FileUploader
                        className="uploadTemplate"
                        multiple={false}
                        uploadMode={"instantly"}
                        labelText=""
                        selectButtonText="SELECCIONAR UN TEMPLATE"
                        onValueChanged={(files) => getFiles(files)}
                    />
                    <div className="gridCollaborators">
                        <fieldset className="FieldsetEvaluators">
                            <legend>Evaluadores</legend>
                            <DataGrid
                                className='dgvEvaluators'
                                dataSource={dtEvaluators}
                                height="580px"
                                width="90%"
                                rowAlternationEnabled={false}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                showBorders={true}
                                paging={{ enabled: false }} // Deshabilitar paginación
                            >
                                <SearchPanel
                                    visible={true}
                                    width={240}
                                    placeholder="Buscar..."
                                />
                                <HeaderFilter><Search enabled={true} /></HeaderFilter>
                                <Column
                                    caption="#"
                                    width={50}
                                    cellRender={renderRowIndex}
                                    alignment="center"
                                    allowSorting={false}
                                    allowFiltering={false}
                                />
                                <Column dataField={"cvetra"} caption='No Empleado' visible={true} width="100px"></Column>
                                <Column dataField={"levelEvaluator"} caption='Nivel' visible={true} width="150px"></Column>
                                <Column dataField={"name_employee"} caption='Nombre' visible={true} width="300px"></Column>
                            </DataGrid>
                        </fieldset>
                        <fieldset className="FieldsetEvaluateds">
                            <legend>Evaluados</legend>
                            <DataGrid
                                className='dgvEvaluateds'
                                dataSource={dtEvaluateds}
                                height="580px"
                                width="90%"
                                rowAlternationEnabled={false}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                showBorders={true}
                                paging={{ enabled: false }} // Deshabilitar paginación
                            >
                                <SearchPanel
                                    visible={true}
                                    width={240}
                                    placeholder="Buscar..."
                                />
                                <HeaderFilter><Search enabled={true} /></HeaderFilter>
                                <Column
                                    caption="#"
                                    width={50}
                                    cellRender={renderRowIndex}
                                    alignment="center"
                                    allowSorting={false}
                                    allowFiltering={false}
                                />
                                <Column dataField={"cvetra"} caption='No Empleado' visible={true} width="100px"></Column>
                                <Column dataField={"name_employee"} caption='Nombre' visible={true} width="300px"></Column>
                            </DataGrid>
                        </fieldset>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state: RootState) => {
    return {
        Select_IdTarea: state.NewEvaluations.Id_Evaluation,
        Select_Title: state.NewEvaluations.Title,
        Select_Description: state.NewEvaluations.Description,
        Select_Evaluators: state.NewEvaluations.Evaluators,
        Select_Evaluateds: state.NewEvaluations.Evaluateds,
        Select_Img: state.NewEvaluations.Img,
    }
}
const mapDispatchToProps = (dispatch: any) => { return {} }
export default connect(mapStateToProps, mapDispatchToProps)(NewEvaluation)