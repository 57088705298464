    import '../view_DetalleCategoria.scss'
    import { useNavigate } from 'react-router-dom';
    import { useDispatch } from 'react-redux';
    import { set_estatus_tarea, set_id_segmentacion, set_nombre_tarea_seleccionada, set_tarea_seleccionada, set_return_view_evaluations, set_type_form, set_was_evaluated, set_name_evaluated } from '../../../../reduxStore/OverBoard/OB_actions';
    import { Button } from 'devextreme-react';
    import notify from 'devextreme/ui/notify';
    import { NullUndefinedEmpty } from '../../../../contexts/GenericFunctions'

    interface IProps {
        prop: any,
        color:any
    }

    const nav = async (navigate: any, dispatch: any, idTarea: string, nombre: string, status: string, idSegmentacion: string, TareaAnterior: any) => {

        if ( TareaAnterior.status !== undefined && TareaAnterior.status !== 'Completed'){
            notify(
                {
                    message: "Favor de completar la tarea anterior para poder continuar", 
                    width: 230,
                    position: {
                        at: "bottom",
                        my: "bottom",
                        of: "#container"
                    }
                }, 
                'info', 
                3000
            )
        }else{
            if (status === 'Completed'){
                notify(
                    {
                        message: "La tarea ya ha sido completada", 
                        width: 230,
                        position: {
                            at: "bottom",
                            my: "bottom",
                            of: "#container"
                        }
                    }, 
                    'info', 
                    3000
                )

                dispatch(set_tarea_seleccionada(idTarea))
                dispatch(set_nombre_tarea_seleccionada(nombre))
                dispatch(set_estatus_tarea(status))
                dispatch(set_id_segmentacion(idSegmentacion))
                dispatch(set_type_form("F"))
                dispatch(set_return_view_evaluations(false))
                dispatch(set_was_evaluated(""))
                dispatch(set_name_evaluated(""))
                await navigate('/DetalleTarea')
            }else{
                dispatch(set_tarea_seleccionada(idTarea))
                dispatch(set_nombre_tarea_seleccionada(nombre))
                dispatch(set_estatus_tarea(status))
                dispatch(set_id_segmentacion(idSegmentacion))
                dispatch(set_type_form("F"))
                dispatch(set_return_view_evaluations(false))
                dispatch(set_was_evaluated(""))
                dispatch(set_name_evaluated(""))
                await navigate('/DetalleTarea')
            }
        }
    }

    function TaskItem({ prop,  color}: IProps) {
        const navigate = useNavigate();
        const dispatch = useDispatch();
        
        return (
            <Button onClick={() => nav(navigate, dispatch, prop.task_id, prop.title, prop.status, prop._id, prop.TareaAnterior)} className={'btnTarea'}>
            {color}
                <div className='IconoTarea'>
                    <img className='ImgIcono' src={`${NullUndefinedEmpty(prop.icon_path) ? 'https://firebasestorage.googleapis.com/v0/b/halconet-27dd9.appspot.com/o/HalcoHumand%2FICONOS_TAREAS%2Flogo-halconet_sinletras.svg?alt=media&token=cd51f543-4a9b-4685-85f7-50f9976b4146' : prop.icon_path}`} alt='Img'/>
                </div>
                <div className={'TareaItem'}>
                    <div style={{ width: '100%'}}>
                        <label style={{ fontWeight: 'bold' }}>Nombre: </label>
                        <label className="task-item-text">{prop.title}</label>
                    </div>
                    <div style={{ width:'100%', alignSelf: 'center', position: 'relative' }}>
                        <label style={{ fontWeight: 'bold' }}>Fecha de asignación: </label>
                        <label className="task-item-info"> {`${prop.create_date.split('T')[0]}`}</label>
                    </div>
                </div>
            </Button>
        );
    }

    export default TaskItem;